<template>
  <div>
    <section class="hearing-aid-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>보청기 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>보청기 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">브랜드명</label>
              </th>
              <td>
                <input
                  type="text"
                  id="title"
                  name="title"
                  v-model="brandname"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <!-- <span class="text-area"></span> -->
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>
              <th scope="row">브랜드 로고</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select
                  v-model="fileMain"
                  v-bind:show-del="fileMain != null && fileMain.name != null"
                ></file-select>
                <span
                  >사이즈 : 최대 300 x 150px / 용량 : 10MB 이하 업로드 (jpg /
                  png / gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select
                      v-model="files[index]"
                      v-bind:show-del="file != null && file.name != null"
                    ></file-select>
                  </div>
                </div>

                <span
                  >사이즈 : 600 x 600px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="url">URL</label>
              </th>
              <td>
                <input type="text" id="url" name="url" v-model="brand_url" />
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'HearingAidList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
import attachments_update_mixin from "../../../mixins/attachments_update_mixin";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect },
  name: "HearingAidEdit",
  mixins: [attachments_update_mixin],
  data() {
    return {
      fileMain: null,

      brand_url: this.item != null ? this.item.outlink_url : "",
      brandname: this.item != null ? this.item.name : "",
      editor: ClassicEditor,
      editorData: this.item != null ? this.item.description : "",
      editorConfig: {
        toolbar: [],
      },
    };
  },
  created() {
    console.log("created => ", this.item);
    this.attachments = this.item.attachments; // 초기 첨부파일 리스트를 저장하여야함.

    // 첨부 파일은
    this.init_attachments_set();
  },
  methods: {
    onSubmitModify() {
      var payload = {
        id: this.item.id,
        product_id: this.item.product_id,
        name: this.brandname,
        outlink_url: this.brand_url,
        description: this.editorData,
      };

      this.$store
        .dispatch("products/req_update_product_brand", payload)
        .then(() => {
          this.onSubmitModifyFiles("products_brands");
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
  },
};
</script>
