<template>
  <div>
    <section class="contact-lens-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>콘택트렌즈 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>콘택트렌즈 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">브랜드명</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="name" />
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <!-- <span class="text-area"></span> -->
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>
              <th scope="row">브랜드 로고</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMain"></file-select>
                <span
                  >사이즈 : 최대 300 x 150px / 용량 : 10MB 이하 업로드 (jpg /
                  png / gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">상세 이미지<span class="required">*</span></th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]"></file-select>
                  </div>
                </div>

                <span
                  >사이즈 : 600 x 600px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)
                  <em
                    >※ 상세 이미지가 최소 1개 이상 등록되어야 업로드
                    가능합니다.</em
                  >
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="url">URL</label>
              </th>
              <td>
                <input type="text" id="url" name="url" v-model="outlink_url" />
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'ContactLensList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";

export default {
  components: { AppFooter, FileSelect },
  name: "ContactLensCreate",
  data() {
    return {
      name: "",
      outlink_url: "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  created() {},
  methods: {
    onClickCreate() {
      console.log("in onClickCreate ==>  name ", this.name);
      console.log("in onClickCreate ==>  editorData ", this.editorData);
      console.log("in onClickCreate ==>  fileMain ", this.fileMain);

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append(
        "description",
        this.editorData != null ? this.editorData : ""
      );
      formData.append(
        "outlink_url",
        this.outlink_url != null ? this.outlink_url : ""
      );
      formData.append("product_id", 3); // 콘택트렌즈

      formData.append("fileMain", this.fileMain);

      var count = 0;
      for (let attfile of this.files) {
        if (attfile != null && attfile != undefined) {
          var fieldname = "files[" + count + "]";
          formData.append(fieldname, attfile);
        }
        count++;
      }

      // axios 요청 = action 요청
      this.$store
        .dispatch("products/create_product_brand", formData)
        .then(() => {
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "ContactLensList" });
          });
        })
        .catch((err) => {
          console.log(" error ", err);
        });
    },
  },
};
</script>
