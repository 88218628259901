<template>
  <div>
    <section class="glasses-frame-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>안경테 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>안경테 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">브랜드명</th>
                <th scope="col">내용</th>
                <th scope="col">등록일</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">해당년도체크</label
                  ><input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>{{ item.id }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'GlassesFrameEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td class="cont-cell">
                  <p v-html="item.description" class="of-hidden" />
                </td>
                <td>
                  {{ $moment(item.created_dttm).format("YYYY.MM.DD") }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link :to="{ name: 'GlassesFrameCreate' }" class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import { mapGetters } from "vuex";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "GlassesFrameList",
  data() {
    return {
      PRODUCT_ID: 1, // 안경테
    };
  },
  computed: {
    ...mapGetters({
      getData: "products/getGlassesFrameList",
      getDataTotalCount: "products/getGlassesFrameTotalCount",
    }),
  },
  created() {
    this.$store.dispatch("products/reqGlassesFrameBrands", {
      page: 1,
      size: this.PAGE_SIZE,
    });
  },
  methods: {
    onSearch(text) {
      this.$store.dispatch("products/reqGlassesFrameBrands", {
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: text,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.$store.dispatch("products/reqGlassesFrameBrands", {
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store
          .dispatch("products/remove_product_brand_list", {
            idarr: this.checkedValues,
            product_id: this.PRODUCT_ID,
          })
          .then(() => {
            this.checkedValues = [];
          })
          .catch((err) => {
            console.log(" error ", err);
            this.checkedValues = [];
          });
      }
    },
  },
};
</script>
<style scoped>
.cont-cell {
  padding-left: 20px;
}
</style>
